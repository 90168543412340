import { Modal, Select, notification } from 'antd/lib';
import { Text } from 'components/basic';
import useApi from 'hooks/useApi';
import React, { useState } from 'react';
import { updateOperator } from 'services/message.service';

const UpdateOperatorModal = ({ ...props }) => {
    const name = props.content?.name;
    const currentRole = props.content?.currentRole;
    const email = props.content?.email;
    const [newRole, setNewRole] = useState('SUPER_ADMIN');
    const roles = [
        { value: 'SUPER_ADMIN', label: 'Super Admin', disabled: currentRole === 'SUPER_ADMIN' },
        { value: 'OPERATOR', label: 'Operator', disabled: currentRole === 'OPERATOR' },
        { value: 'FINANCE', label: 'Finance', disabled: currentRole === 'FINANCE' },
        { value: 'FINANCE_MANAGER', label: 'Finance Manager', disabled: currentRole === 'FINANCE_MANAGER' },
        { value: 'CITY_MANAGER', label: 'City Manager', disabled: currentRole === 'CITY_MANAGER' },
        { value: 'PRODUCT_OWNER', label: 'Product Owner', disabled: currentRole === 'PRODUCT_OWNER' },
        { value: 'TRANSPORT_OPS', label: 'Transport Ops', disabled: currentRole === 'TRANSPORT_OPS' },
        { value: 'IT_OPS', label: 'IT Ops', disabled: currentRole === 'IT_OPS' },
        { value: 'QUALITY_ASSURANCE', label: 'Quality Assurance', disabled: currentRole === 'QUALITY_ASSURANCE' },
        { value: 'SERVICE_EXCELLENT', label: 'Service Excellence', disabled: currentRole === 'SERVICE_EXCELLENT' },
        { value: 'MARKETING', label: 'Marketing', disabled: currentRole === 'MARKETING' },
        { value: 'ONBOARDING', label: 'Transport Ops - Onboarding Team', disabled: currentRole === 'ONBOARDING' },
        { value: 'DREX', label: 'Transport Ops - DREX Team', disabled: currentRole === 'DREX' },
        { value: 'OPS_STRATEGY_1', label: 'Transport Ops - Strategy 1', disabled: currentRole === 'OPS_STRATEGY_1' },
        { value: 'OPS_STRATEGY_2', label: 'Transport Ops - Strategy 2', disabled: currentRole === 'OPS_STRATEGY_2' },
        { value: 'EXPANSION', label: 'Transport Ops - Expansion', disabled: currentRole === 'EXPANSION' },
        { value: 'SERVICE_EXCELLENT_AGENT', label: 'SE Agent', disabled: currentRole === 'SERVICE_EXCELLENT_AGENT' },
        {
            value: 'SERVICE_EXCELLENT_SUPPORT',
            label: 'SE Support',
            disabled: currentRole === 'SERVICE_EXCELLENT_SUPPORT',
        },
        {
            value: 'SERVICE_EXCELLENT_IP',
            label: 'Service Excellent IP',
            disabled: currentRole === 'SERVICE_EXCELLENT_IP',
        },
        { value: 'VISMIN_ONBOARDING', label: 'VisMin Onboarding', disabled: currentRole === 'VISMIN_ONBOARDING' },
        { value: 'ONBOARDING_MANAGER', label: 'Onboarding Manager', disabled: currentRole === 'ONBOARDING_MANAGER' },
    ];

    const convertValueToLabel = (value: any) => {
        const role = roles.find(role => role.value === value);
        return role ? role.label : 'Unknown Role';
    };

    const { request, loading } = useApi({
        api: updateOperator,
    });

    const onUpdate = async () => {
        try {
            const body = {
                email: email,
                user_role: newRole,
            };
            const res = await request({ body });
            if (res.data) {
                notification.success({
                    message: 'Success',
                    description: (
                        <div>
                            User role for <strong>{name}</strong> has been updated to{' '}
                            <strong>{convertValueToLabel(newRole)}</strong> successfully.
                        </div>
                    ),
                    duration: 3,
                });
                props.close();
                props.refreshList();
            } else if (res.error) {
                notification.error({
                    message: 'Error',
                    description: (
                        <div>
                            <Text>
                                <strong>Error Code:</strong> {res.error.errorCode}
                            </Text>
                            <Text>
                                <strong>Error Message:</strong> {res.error.message}
                            </Text>
                            <Text>
                                <strong>Name: </strong> {name}
                            </Text>
                        </div>
                    ),
                    duration: 3,
                });
                setNewRole('SUPER_ADMIN');
                props.close();
            }
        } catch (e) {
            notification.error({
                message: 'Something Went Wrong',
                description: (
                    <div>
                        Unable to update the role for <strong>{name}</strong>. Please try again later.
                    </div>
                ),
                duration: 3,
            });
            props.close();
        }
    };

    return (
        <Modal
            {...props}
            title="Update User Roles"
            okText="Save"
            onOk={onUpdate}
            okButtonProps={{ disabled: currentRole === newRole }}
            confirmLoading={loading}
            destroyOnClose
            onCancel={() => {
                setNewRole('SUPER_ADMIN');
                props.close();
            }}
        >
            Select the new role for the user from the options below and click 'Save' to update their role in the system.
            <div>
                <Text color="text-gray" fontWeight="font-semibold" className="mt-md mb-xs">
                    User Summary
                </Text>
                <div>
                    <div className="h-[3rem] border-solid border border-gray-300 py-xs px-md rounded-t-lg flex justify-between items-center">
                        <Text color="text-gray" fontWeight="font-semibold" size="text-sm">
                            Name
                        </Text>
                        <div className="text-right my-xs">
                            <Text fontWeight="font-semibold" size="text-md" className="leading-none">
                                {name}
                            </Text>
                            <Text className="leading-none mt-xs">{email}</Text>
                        </div>
                    </div>
                    <div className="h-[3rem] border-solid border-t-0 border border-gray-300 py-xs px-md rounded-b-lg flex justify-between items-center">
                        <Text color="text-gray" fontWeight="font-semibold" size="text-sm">
                            Current Role
                        </Text>
                        <div className="text-right">
                            <Text fontWeight="font-semibold" size="text-md">
                                {convertValueToLabel(currentRole)}
                            </Text>
                        </div>
                    </div>
                    <div className="mt-md">
                        <Text type="label" fontWeight="font-semibold">
                            New Role<span className="text-red-500">*</span>
                        </Text>
                        <Select
                            options={roles}
                            defaultValue="SUPER_ADMIN"
                            className="w-full"
                            value={newRole}
                            onChange={value => {
                                setNewRole(value);
                            }}
                        />
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default UpdateOperatorModal;
