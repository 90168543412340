import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Button,Modal, Tag } from 'antd/lib';
import { Table } from 'components';
import { useNavigate } from 'react-router-dom';
import { Dropdown, Text } from 'components/basic';
import { DownOutlined } from '@ant-design/icons';
import ListLayout from 'components/layouts/ContentLayout/ListLayout';
import classnames from 'clsx';
import { useParams} from 'react-router-dom';

const Drivers = ({tableData = { drivers: [] }, exportData = {exportData:[]}, segmentData = {data: []} }: any) => {
    let { id } = useParams();
    const [data, setData] = useState([]);
    const [pagination, setPagination]: any = useState({
        defaultCurrent: 1,
        defaultPageSize: 10,
    });
    const [isModalVisible, setIsModalVisible] = useState(false);

    const navigate = useNavigate();

    const handleModalClose = () => {
        setIsModalVisible(false);
    };

    const handleModalSubmit = () => {
        navigate(`/driver-segments/upload-driver-segments`, {
            state: { exportData: exportData, segmentId:id, segmentData: segmentData },
          })
    };

    useEffect(() => {
        if (tableData?.drivers) {
            setData(tableData.drivers.map((driver: { id: any }) => ({ ...driver, key: driver.id })));
            let pagination = tableData.pagination;
            let p = {
                ...pagination,
                current: pagination?.page,
                defaultCurrent: 1,
                pageSize: pagination?.page_size,
                total: pagination?.total_count,
            };
            setPagination(p);
        }
    }, [tableData]);

    const ColumnActions = useCallback(
        (user: any) => {
            return (
                <Dropdown
                    menu={{
                        items: [
                            {
                                label: 'View',
                                key: '1',
                                onClick: () => {
                                    navigate(`/drivers/${user.id}/overview`);

                                },
                            }
                        ],
                    }}
                >
                    <Button type="ghost">
                        <DownOutlined />
                    </Button>
                </Dropdown>
            );
        },
        [navigate]
    );

    const columns = [
        {
            title: 'Driver ID',
            render: (user: any) => (
                <div className="flex items-center">
                    <a href={`/drivers/${user.id}/overview`} target="_blank" rel="noopener noreferrer">
                        {user.public_id}
                    </a>
                </div>
            ),
        },
        {
            title: 'Driver',
            key: 'name',
            render: (user: any) => (
                <Text className={classnames({ 'text-gray-500': !user.first_name })}>
                    {user.first_name || '-'} {user.last_name || '-'}
                </Text>
            ),
        },
        {
            title: 'Status',
            render: (user: any) => (
                <div className="flex items-start flex-col">
                    {user.account_status == 'ACTIVE' && <Tag color="green">Active</Tag>}
                    {user.account_status == 'INACTIVE' && <Tag color="red">Inactive</Tag>}
                    {user.account_status == 'SUSPENDED' && <Tag color="orange">Suspended</Tag>}
                    {user.account_status == 'BANNED' && <Tag>Banned</Tag>}
                    {user.account_status == 'SHADOW_BANNED' && <Tag>Shadow Banned</Tag>}
                    {user.account_status == 'SHADOW_SUSPENDED' && <Tag color="orange">Shadow Suspended</Tag>}
                </div>
            ),
        },
        {
            title: 'Created at',
            dataIndex: 'created_at',
            key: 'created_at',
        },
        {
            title: '',
            className: 'action-cell',
            render: (user: any) => ColumnActions(user),
        },
    ];

    return (
        <ListLayout
            actionComponent={
                <div className="flex">
                    <Button
                        className='mr-2'
                        onClick={() =>setIsModalVisible(true)}>
                        Edit
                    </Button>
                    <Button
                        type="primary"
                        onClick={() => {
                            if (data.length === 0) {
                                console.warn("No driver data available for export.");
                                return;
                            }
                            const csvContent = data.map((driver: any) => driver.id).join("\n");
                            const blob = new Blob([csvContent], { type: "text/csv" });
                            const url = URL.createObjectURL(blob);
                            const link = document.createElement("a");
                            link.href = url;
                            link.download = "drivers.csv";
                            document.body.appendChild(link);
                            link.click();
                            document.body.removeChild(link);
                        }}
                    >
                        Export Drivers
                    </Button>
                </div>
            }
        >
            <Table
                columns={columns}
                dataSource={data}
                pagination={{
                    ...pagination,
                    showSizeChanger: true,
                    showQuickJumper: true,
                    showTotal: (total: any) => `${total?.toLocaleString()} items`,
                }}
            />
            <Modal
                title="Overwrite CSV?"
                open={isModalVisible}
                onCancel={handleModalClose}
                onOk={handleModalSubmit}
                okText="Confirm"
            >
                Are you sure you want to overwrite the existing file? This action cannot be undone.
            </Modal>
        </ListLayout>
    );
};

export default Drivers;
