interface RolePermissions {
    [page: string]: string[]; // Permissions for each page
}

interface Roles {
    [role: string]: RolePermissions; // Permissions for each role
}

export const PagesEnum = {
    DRIVERS: 'Drivers',
    BIKER_STATUS_UPDATE: 'Biker Status Update',
    BIKER_ACTIVATION: 'Biker Activation',
    CUSTOMERS: 'Customers',
    TRIPS: 'Trips',
    WALLET_HISTORY: 'Wallet History',
    INSTALLMENT: 'Installment',
    INCENTIVES: 'Incentives',
    TOP_UP_AND_DEDUCTIONS: 'Top ups and Deductions',
    GENERAL_SETTINGS: 'General Settings',
    OPERATORS: 'Operators',
    LOGS: 'Logs',
    GCASH_TRANSACTIONS: 'Gcash Transactions',
    CUSTOMER_TRANSACTIONS: 'Customer Transactions',
    DRIVER_TRANSACTIONS: 'Driver Transactions',
    ZONES: 'Zones',
    DRIVER_SEGMENTS: 'Driver Segments',
};

export const Accessibility = {
    VIEW: 'view',
    EDIT: 'edit',
    DELETE: 'delete',
    ALL: 'all',
    EDIT_DRIVER_MOBILE_NUMBER: 'edit_driver_mobile_number',
    CANCEL_TRIP: 'cancel_trip',
    UPDATE_BIKER_SERVICEABILITY: 'update_biker_serviceability',
};

const permissions: Roles = {
    OPERATOR: {
        [PagesEnum.DRIVERS]: [Accessibility.VIEW],
        [PagesEnum.BIKER_STATUS_UPDATE]: [],
        [PagesEnum.BIKER_ACTIVATION]: [Accessibility.VIEW],
        [PagesEnum.CUSTOMERS]: [Accessibility.VIEW],
        [PagesEnum.TRIPS]: [Accessibility.VIEW],
        [PagesEnum.WALLET_HISTORY]: [Accessibility.VIEW],
        [PagesEnum.INSTALLMENT]: [Accessibility.VIEW],
        [PagesEnum.INCENTIVES]: [Accessibility.VIEW],
        [PagesEnum.TOP_UP_AND_DEDUCTIONS]: [Accessibility.VIEW],
        [PagesEnum.GENERAL_SETTINGS]: [Accessibility.VIEW],
        [PagesEnum.OPERATORS]: [Accessibility.VIEW],
        [PagesEnum.LOGS]: [Accessibility.VIEW],
        [PagesEnum.GCASH_TRANSACTIONS]: [Accessibility.VIEW],
        [PagesEnum.CUSTOMER_TRANSACTIONS]: [Accessibility.VIEW],
        [PagesEnum.DRIVER_TRANSACTIONS]: [Accessibility.VIEW],
        [PagesEnum.ZONES]: [],
    },
    SUPER_ADMIN: {
        [PagesEnum.DRIVERS]: [Accessibility.ALL],
        [PagesEnum.BIKER_STATUS_UPDATE]: [Accessibility.ALL],
        [PagesEnum.BIKER_ACTIVATION]: [Accessibility.ALL],
        [PagesEnum.CUSTOMERS]: [Accessibility.ALL],
        [PagesEnum.TRIPS]: [Accessibility.ALL],
        [PagesEnum.WALLET_HISTORY]: [Accessibility.ALL],
        [PagesEnum.INSTALLMENT]: [Accessibility.ALL],
        [PagesEnum.INCENTIVES]: [Accessibility.ALL],
        [PagesEnum.TOP_UP_AND_DEDUCTIONS]: [Accessibility.ALL],
        [PagesEnum.GENERAL_SETTINGS]: [Accessibility.ALL],
        [PagesEnum.OPERATORS]: [Accessibility.ALL],
        [PagesEnum.LOGS]: [Accessibility.ALL],
        [PagesEnum.GCASH_TRANSACTIONS]: [Accessibility.VIEW],
        [PagesEnum.CUSTOMER_TRANSACTIONS]: [Accessibility.VIEW],
        [PagesEnum.DRIVER_TRANSACTIONS]: [Accessibility.VIEW],
        [PagesEnum.ZONES]: [Accessibility.ALL],
        [PagesEnum.DRIVER_SEGMENTS]: [Accessibility.ALL],
    },
    FINANCE: {
        [PagesEnum.DRIVERS]: [Accessibility.VIEW],
        [PagesEnum.BIKER_STATUS_UPDATE]: [],
        [PagesEnum.BIKER_ACTIVATION]: [],
        [PagesEnum.CUSTOMERS]: [Accessibility.VIEW],
        [PagesEnum.TRIPS]: [Accessibility.VIEW],
        [PagesEnum.WALLET_HISTORY]: [Accessibility.VIEW, Accessibility.EDIT, Accessibility.ALL],
        [PagesEnum.INSTALLMENT]: [Accessibility.VIEW],
        [PagesEnum.INCENTIVES]: [Accessibility.VIEW, Accessibility.EDIT, Accessibility.ALL],
        [PagesEnum.TOP_UP_AND_DEDUCTIONS]: [Accessibility.EDIT, Accessibility.ALL],
        [PagesEnum.GENERAL_SETTINGS]: [Accessibility.VIEW],
        [PagesEnum.OPERATORS]: [],
        [PagesEnum.LOGS]: [],
        [PagesEnum.GCASH_TRANSACTIONS]: [Accessibility.ALL],
        [PagesEnum.CUSTOMER_TRANSACTIONS]: [Accessibility.ALL],
        [PagesEnum.DRIVER_TRANSACTIONS]: [Accessibility.ALL],
        [PagesEnum.ZONES]: [],
    },
    FINANCE_MANAGER: {
        [PagesEnum.DRIVERS]: [Accessibility.VIEW, Accessibility.EDIT],
        [PagesEnum.BIKER_STATUS_UPDATE]: [],
        [PagesEnum.BIKER_ACTIVATION]: [],
        [PagesEnum.CUSTOMERS]: [Accessibility.VIEW, Accessibility.EDIT],
        [PagesEnum.TRIPS]: [Accessibility.VIEW, Accessibility.EDIT],
        [PagesEnum.WALLET_HISTORY]: [Accessibility.VIEW, Accessibility.EDIT],
        [PagesEnum.INSTALLMENT]: [Accessibility.VIEW, Accessibility.EDIT],
        [PagesEnum.INCENTIVES]: [Accessibility.VIEW, Accessibility.EDIT],
        [PagesEnum.TOP_UP_AND_DEDUCTIONS]: [Accessibility.VIEW, Accessibility.EDIT],
        [PagesEnum.GENERAL_SETTINGS]: [Accessibility.VIEW],
        [PagesEnum.OPERATORS]: [Accessibility.VIEW],
        [PagesEnum.LOGS]: [Accessibility.VIEW],
        [PagesEnum.GCASH_TRANSACTIONS]: [Accessibility.ALL],
        [PagesEnum.CUSTOMER_TRANSACTIONS]: [Accessibility.ALL],
        [PagesEnum.DRIVER_TRANSACTIONS]: [Accessibility.ALL],
        [PagesEnum.ZONES]: [Accessibility.ALL],
    },
    CITY_MANAGER: {
        [PagesEnum.DRIVERS]: [Accessibility.VIEW, Accessibility.EDIT],
        [PagesEnum.BIKER_STATUS_UPDATE]: [Accessibility.VIEW, Accessibility.EDIT],
        [PagesEnum.BIKER_ACTIVATION]: [Accessibility.VIEW, Accessibility.EDIT],
        [PagesEnum.CUSTOMERS]: [Accessibility.VIEW, Accessibility.EDIT],
        [PagesEnum.TRIPS]: [Accessibility.VIEW, Accessibility.EDIT],
        [PagesEnum.WALLET_HISTORY]: [Accessibility.VIEW, Accessibility.EDIT],
        [PagesEnum.INSTALLMENT]: [Accessibility.VIEW, Accessibility.EDIT],
        [PagesEnum.INCENTIVES]: [Accessibility.VIEW, Accessibility.EDIT],
        [PagesEnum.TOP_UP_AND_DEDUCTIONS]: [Accessibility.VIEW, Accessibility.EDIT],
        [PagesEnum.GENERAL_SETTINGS]: [Accessibility.VIEW, Accessibility.EDIT],
        [PagesEnum.OPERATORS]: [Accessibility.VIEW],
        [PagesEnum.LOGS]: [Accessibility.VIEW],
        [PagesEnum.GCASH_TRANSACTIONS]: [Accessibility.VIEW],
        [PagesEnum.CUSTOMER_TRANSACTIONS]: [Accessibility.VIEW],
        [PagesEnum.DRIVER_TRANSACTIONS]: [Accessibility.VIEW],
        [PagesEnum.ZONES]: [Accessibility.ALL],
    },
    PRODUCT_OWNER: {
        [PagesEnum.DRIVERS]: [Accessibility.VIEW],
        [PagesEnum.BIKER_STATUS_UPDATE]: [],
        [PagesEnum.BIKER_ACTIVATION]: [Accessibility.VIEW],
        [PagesEnum.CUSTOMERS]: [Accessibility.VIEW],
        [PagesEnum.TRIPS]: [Accessibility.VIEW],
        [PagesEnum.WALLET_HISTORY]: [Accessibility.VIEW],
        [PagesEnum.INSTALLMENT]: [Accessibility.VIEW],
        [PagesEnum.INCENTIVES]: [Accessibility.VIEW],
        [PagesEnum.TOP_UP_AND_DEDUCTIONS]: [Accessibility.VIEW],
        [PagesEnum.GENERAL_SETTINGS]: [Accessibility.VIEW],
        [PagesEnum.OPERATORS]: [Accessibility.VIEW],
        [PagesEnum.LOGS]: [Accessibility.VIEW],
        [PagesEnum.GCASH_TRANSACTIONS]: [Accessibility.VIEW],
        [PagesEnum.CUSTOMER_TRANSACTIONS]: [Accessibility.VIEW],
        [PagesEnum.DRIVER_TRANSACTIONS]: [Accessibility.VIEW],
        [PagesEnum.ZONES]: [],
        [PagesEnum.DRIVER_SEGMENTS]: [Accessibility.ALL],
    },
    TRANSPORT_OPS: {
        [PagesEnum.DRIVERS]: [Accessibility.VIEW],
        [PagesEnum.BIKER_STATUS_UPDATE]: [Accessibility.VIEW],
        [PagesEnum.BIKER_ACTIVATION]: [Accessibility.VIEW],
        [PagesEnum.CUSTOMERS]: [Accessibility.VIEW],
        [PagesEnum.TRIPS]: [Accessibility.VIEW],
        [PagesEnum.WALLET_HISTORY]: [Accessibility.VIEW],
        [PagesEnum.INSTALLMENT]: [Accessibility.VIEW],
        [PagesEnum.INCENTIVES]: [Accessibility.VIEW],
        [PagesEnum.TOP_UP_AND_DEDUCTIONS]: [Accessibility.VIEW],
        [PagesEnum.GENERAL_SETTINGS]: [Accessibility.VIEW],
        [PagesEnum.OPERATORS]: [Accessibility.VIEW],
        [PagesEnum.LOGS]: [Accessibility.VIEW],
        [PagesEnum.GCASH_TRANSACTIONS]: [],
        [PagesEnum.CUSTOMER_TRANSACTIONS]: [],
        [PagesEnum.DRIVER_TRANSACTIONS]: [],
        [PagesEnum.ZONES]: [],
    },
    IT_OPS: {
        [PagesEnum.DRIVERS]: [],
        [PagesEnum.BIKER_STATUS_UPDATE]: [],
        [PagesEnum.BIKER_ACTIVATION]: [],
        [PagesEnum.CUSTOMERS]: [],
        [PagesEnum.TRIPS]: [],
        [PagesEnum.WALLET_HISTORY]: [],
        [PagesEnum.INSTALLMENT]: [],
        [PagesEnum.INCENTIVES]: [],
        [PagesEnum.TOP_UP_AND_DEDUCTIONS]: [],
        [PagesEnum.GENERAL_SETTINGS]: [],
        [PagesEnum.OPERATORS]: [Accessibility.ALL],
        [PagesEnum.LOGS]: [Accessibility.ALL],
        [PagesEnum.GCASH_TRANSACTIONS]: [],
        [PagesEnum.CUSTOMER_TRANSACTIONS]: [],
        [PagesEnum.DRIVER_TRANSACTIONS]: [],
        [PagesEnum.ZONES]: [],
    },
    QUALITY_ASSURANCE: {
        [PagesEnum.DRIVERS]: [Accessibility.VIEW],
        [PagesEnum.BIKER_STATUS_UPDATE]: [Accessibility.VIEW],
        [PagesEnum.BIKER_ACTIVATION]: [Accessibility.VIEW],
        [PagesEnum.CUSTOMERS]: [Accessibility.VIEW],
        [PagesEnum.TRIPS]: [Accessibility.VIEW],
        [PagesEnum.WALLET_HISTORY]: [Accessibility.VIEW],
        [PagesEnum.INSTALLMENT]: [Accessibility.VIEW],
        [PagesEnum.INCENTIVES]: [Accessibility.VIEW],
        [PagesEnum.TOP_UP_AND_DEDUCTIONS]: [Accessibility.VIEW],
        [PagesEnum.GENERAL_SETTINGS]: [Accessibility.VIEW],
        [PagesEnum.OPERATORS]: [Accessibility.VIEW],
        [PagesEnum.LOGS]: [Accessibility.VIEW],
        [PagesEnum.GCASH_TRANSACTIONS]: [],
        [PagesEnum.CUSTOMER_TRANSACTIONS]: [],
        [PagesEnum.DRIVER_TRANSACTIONS]: [],
        [PagesEnum.ZONES]: [],
    },
    SERVICE_EXCELLENT: {
        [PagesEnum.DRIVERS]: [Accessibility.VIEW, Accessibility.EDIT],
        [PagesEnum.BIKER_STATUS_UPDATE]: [Accessibility.VIEW, Accessibility.EDIT],
        [PagesEnum.BIKER_ACTIVATION]: [Accessibility.VIEW],
        [PagesEnum.CUSTOMERS]: [Accessibility.VIEW, Accessibility.EDIT],
        [PagesEnum.TRIPS]: [Accessibility.VIEW, Accessibility.EDIT],
        [PagesEnum.WALLET_HISTORY]: [Accessibility.VIEW],
        [PagesEnum.INSTALLMENT]: [Accessibility.VIEW],
        [PagesEnum.INCENTIVES]: [Accessibility.VIEW],
        [PagesEnum.TOP_UP_AND_DEDUCTIONS]: [Accessibility.VIEW, Accessibility.EDIT],
        [PagesEnum.GENERAL_SETTINGS]: [Accessibility.VIEW],
        [PagesEnum.OPERATORS]: [Accessibility.VIEW, Accessibility.EDIT],
        [PagesEnum.LOGS]: [Accessibility.VIEW],
        [PagesEnum.GCASH_TRANSACTIONS]: [Accessibility.VIEW, Accessibility.EDIT],
        [PagesEnum.CUSTOMER_TRANSACTIONS]: [Accessibility.VIEW, Accessibility.EDIT],
        [PagesEnum.DRIVER_TRANSACTIONS]: [Accessibility.VIEW, Accessibility.EDIT],
        [PagesEnum.ZONES]: [Accessibility.VIEW, Accessibility.EDIT],
    },
    MARKETING: {
        [PagesEnum.DRIVERS]: [Accessibility.VIEW],
        [PagesEnum.BIKER_STATUS_UPDATE]: [],
        [PagesEnum.BIKER_ACTIVATION]: [],
        [PagesEnum.CUSTOMERS]: [Accessibility.VIEW],
        [PagesEnum.TRIPS]: [Accessibility.VIEW],
        [PagesEnum.WALLET_HISTORY]: [],
        [PagesEnum.INSTALLMENT]: [],
        [PagesEnum.INCENTIVES]: [],
        [PagesEnum.TOP_UP_AND_DEDUCTIONS]: [],
        [PagesEnum.GENERAL_SETTINGS]: [],
        [PagesEnum.OPERATORS]: [],
        [PagesEnum.LOGS]: [],
        [PagesEnum.ZONES]: [Accessibility.VIEW, Accessibility.EDIT, Accessibility.ALL],
    },
    ONBOARDING: {
        [PagesEnum.DRIVERS]: [Accessibility.VIEW],
        [PagesEnum.BIKER_STATUS_UPDATE]: [],
        [PagesEnum.BIKER_ACTIVATION]: [Accessibility.VIEW, Accessibility.EDIT],
        [PagesEnum.CUSTOMERS]: [],
        [PagesEnum.TRIPS]: [],
        [PagesEnum.WALLET_HISTORY]: [],
        [PagesEnum.INSTALLMENT]: [],
        [PagesEnum.INCENTIVES]: [],
        [PagesEnum.TOP_UP_AND_DEDUCTIONS]: [],
        [PagesEnum.GENERAL_SETTINGS]: [],
        [PagesEnum.OPERATORS]: [],
        [PagesEnum.LOGS]: [],
        [PagesEnum.ZONES]: [],
    },
    DREX: {
        [PagesEnum.DRIVERS]: [Accessibility.VIEW],
        [PagesEnum.BIKER_STATUS_UPDATE]: [],
        [PagesEnum.BIKER_ACTIVATION]: [],
        [PagesEnum.CUSTOMERS]: [],
        [PagesEnum.TRIPS]: [Accessibility.VIEW],
        [PagesEnum.WALLET_HISTORY]: [],
        [PagesEnum.INSTALLMENT]: [],
        [PagesEnum.INCENTIVES]: [],
        [PagesEnum.TOP_UP_AND_DEDUCTIONS]: [],
        [PagesEnum.GENERAL_SETTINGS]: [],
        [PagesEnum.OPERATORS]: [],
        [PagesEnum.LOGS]: [],
        [PagesEnum.ZONES]: [],
    },
    OPS_STRATEGY_1: {
        [PagesEnum.DRIVERS]: [
            Accessibility.VIEW,
            Accessibility.EDIT_DRIVER_MOBILE_NUMBER,
            Accessibility.UPDATE_BIKER_SERVICEABILITY,
        ],
        [PagesEnum.BIKER_STATUS_UPDATE]: [],
        [PagesEnum.BIKER_ACTIVATION]: [],
        [PagesEnum.CUSTOMERS]: [Accessibility.VIEW],
        [PagesEnum.TRIPS]: [Accessibility.VIEW, Accessibility.CANCEL_TRIP],
        [PagesEnum.WALLET_HISTORY]: [Accessibility.VIEW],
        [PagesEnum.INSTALLMENT]: [Accessibility.VIEW],
        [PagesEnum.INCENTIVES]: [Accessibility.VIEW],
        [PagesEnum.TOP_UP_AND_DEDUCTIONS]: [Accessibility.VIEW],
        [PagesEnum.GENERAL_SETTINGS]: [],
        [PagesEnum.OPERATORS]: [],
        [PagesEnum.LOGS]: [],
        [PagesEnum.ZONES]: [],
        [PagesEnum.DRIVER_SEGMENTS]: [Accessibility.ALL],
    },
    OPS_STRATEGY_2: {
        [PagesEnum.DRIVERS]: [Accessibility.VIEW],
        [PagesEnum.BIKER_STATUS_UPDATE]: [],
        [PagesEnum.BIKER_ACTIVATION]: [],
        [PagesEnum.CUSTOMERS]: [Accessibility.VIEW],
        [PagesEnum.TRIPS]: [Accessibility.VIEW],
        [PagesEnum.WALLET_HISTORY]: [Accessibility.VIEW],
        [PagesEnum.INSTALLMENT]: [Accessibility.EDIT],
        [PagesEnum.INCENTIVES]: [Accessibility.EDIT],
        [PagesEnum.TOP_UP_AND_DEDUCTIONS]: [Accessibility.EDIT],
        [PagesEnum.GENERAL_SETTINGS]: [Accessibility.VIEW],
        [PagesEnum.OPERATORS]: [],
        [PagesEnum.LOGS]: [],
        [PagesEnum.ZONES]: [],
        [PagesEnum.DRIVER_SEGMENTS]: [Accessibility.ALL],
    },
    EXPANSION: {
        [PagesEnum.DRIVERS]: [Accessibility.VIEW, Accessibility.EDIT],
        [PagesEnum.BIKER_STATUS_UPDATE]: [],
        [PagesEnum.BIKER_ACTIVATION]: [],
        [PagesEnum.CUSTOMERS]: [Accessibility.VIEW, Accessibility.EDIT],
        [PagesEnum.TRIPS]: [],
        [PagesEnum.WALLET_HISTORY]: [],
        [PagesEnum.INSTALLMENT]: [],
        [PagesEnum.INCENTIVES]: [],
        [PagesEnum.TOP_UP_AND_DEDUCTIONS]: [],
        [PagesEnum.GENERAL_SETTINGS]: [],
        [PagesEnum.OPERATORS]: [],
        [PagesEnum.LOGS]: [],
        [PagesEnum.ZONES]: [],
    },
    SERVICE_EXCELLENT_AGENT: {
        [PagesEnum.DRIVERS]: [Accessibility.VIEW, Accessibility.EDIT_DRIVER_MOBILE_NUMBER],
        [PagesEnum.BIKER_STATUS_UPDATE]: [],
        [PagesEnum.BIKER_ACTIVATION]: [Accessibility.VIEW],
        [PagesEnum.CUSTOMERS]: [Accessibility.VIEW],
        [PagesEnum.TRIPS]: [Accessibility.VIEW],
        [PagesEnum.WALLET_HISTORY]: [Accessibility.VIEW],
        [PagesEnum.INSTALLMENT]: [Accessibility.VIEW],
        [PagesEnum.INCENTIVES]: [Accessibility.VIEW],
        [PagesEnum.TOP_UP_AND_DEDUCTIONS]: [Accessibility.VIEW],
        [PagesEnum.GENERAL_SETTINGS]: [Accessibility.VIEW],
        [PagesEnum.OPERATORS]: [Accessibility.VIEW],
        [PagesEnum.LOGS]: [Accessibility.VIEW],
        [PagesEnum.GCASH_TRANSACTIONS]: [Accessibility.VIEW],
        [PagesEnum.CUSTOMER_TRANSACTIONS]: [Accessibility.VIEW],
        [PagesEnum.DRIVER_TRANSACTIONS]: [Accessibility.VIEW],
        [PagesEnum.ZONES]: [],
    },
    SERVICE_EXCELLENT_SUPPORT: {
        [PagesEnum.DRIVERS]: [Accessibility.VIEW, Accessibility.EDIT],
        [PagesEnum.BIKER_STATUS_UPDATE]: [Accessibility.VIEW, Accessibility.EDIT],
        [PagesEnum.BIKER_ACTIVATION]: [Accessibility.VIEW, Accessibility.EDIT],
        [PagesEnum.CUSTOMERS]: [Accessibility.VIEW, Accessibility.EDIT],
        [PagesEnum.TRIPS]: [Accessibility.VIEW, Accessibility.EDIT],
        [PagesEnum.WALLET_HISTORY]: [Accessibility.VIEW],
        [PagesEnum.INSTALLMENT]: [Accessibility.VIEW],
        [PagesEnum.INCENTIVES]: [Accessibility.VIEW],
        [PagesEnum.TOP_UP_AND_DEDUCTIONS]: [Accessibility.VIEW, Accessibility.EDIT],
        [PagesEnum.GENERAL_SETTINGS]: [Accessibility.VIEW],
        [PagesEnum.OPERATORS]: [Accessibility.VIEW, Accessibility.EDIT],
        [PagesEnum.LOGS]: [Accessibility.VIEW],
        [PagesEnum.GCASH_TRANSACTIONS]: [Accessibility.VIEW],
        [PagesEnum.CUSTOMER_TRANSACTIONS]: [Accessibility.VIEW],
        [PagesEnum.DRIVER_TRANSACTIONS]: [Accessibility.VIEW],
        [PagesEnum.ZONES]: [],
    },
    SERVICE_EXCELLENT_IP: {
        [PagesEnum.DRIVERS]: [Accessibility.VIEW, Accessibility.EDIT],
        [PagesEnum.BIKER_STATUS_UPDATE]: [Accessibility.VIEW, Accessibility.EDIT],
        [PagesEnum.BIKER_ACTIVATION]: [Accessibility.VIEW, Accessibility.EDIT],
        [PagesEnum.CUSTOMERS]: [Accessibility.VIEW, Accessibility.EDIT],
        [PagesEnum.TRIPS]: [Accessibility.VIEW, Accessibility.EDIT],
        [PagesEnum.WALLET_HISTORY]: [Accessibility.VIEW],
        [PagesEnum.INSTALLMENT]: [Accessibility.VIEW],
        [PagesEnum.INCENTIVES]: [Accessibility.VIEW],
        [PagesEnum.TOP_UP_AND_DEDUCTIONS]: [Accessibility.VIEW, Accessibility.EDIT],
        [PagesEnum.GENERAL_SETTINGS]: [Accessibility.VIEW],
        [PagesEnum.OPERATORS]: [Accessibility.VIEW, Accessibility.EDIT],
        [PagesEnum.LOGS]: [Accessibility.VIEW],
        [PagesEnum.GCASH_TRANSACTIONS]: [Accessibility.VIEW],
        [PagesEnum.CUSTOMER_TRANSACTIONS]: [Accessibility.VIEW],
        [PagesEnum.DRIVER_TRANSACTIONS]: [Accessibility.VIEW],
        [PagesEnum.ZONES]: [],
    },
    VISMIN_ONBOARDING: {
        [PagesEnum.DRIVERS]: [Accessibility.VIEW, Accessibility.EDIT],
        [PagesEnum.BIKER_STATUS_UPDATE]: [Accessibility.VIEW, Accessibility.EDIT],
        [PagesEnum.BIKER_ACTIVATION]: [],
        [PagesEnum.CUSTOMERS]: [Accessibility.VIEW, Accessibility.EDIT],
        [PagesEnum.TRIPS]: [Accessibility.VIEW, Accessibility.EDIT],
        [PagesEnum.WALLET_HISTORY]: [Accessibility.VIEW, Accessibility.EDIT, Accessibility.ALL],
        [PagesEnum.INSTALLMENT]: [Accessibility.VIEW, Accessibility.ALL],
        [PagesEnum.INCENTIVES]: [Accessibility.VIEW, Accessibility.ALL],
        [PagesEnum.TOP_UP_AND_DEDUCTIONS]: [Accessibility.VIEW, Accessibility.EDIT],
        [PagesEnum.GENERAL_SETTINGS]: [Accessibility.VIEW],
        [PagesEnum.OPERATORS]: [Accessibility.VIEW],
        [PagesEnum.LOGS]: [Accessibility.VIEW],
        [PagesEnum.GCASH_TRANSACTIONS]: [],
        [PagesEnum.CUSTOMER_TRANSACTIONS]: [],
        [PagesEnum.DRIVER_TRANSACTIONS]: [],
        [PagesEnum.ZONES]: [Accessibility.VIEW, Accessibility.EDIT],
    },
    ONBOARDING_MANAGER: {
        [PagesEnum.DRIVERS]: [Accessibility.VIEW, Accessibility.EDIT],
        [PagesEnum.BIKER_STATUS_UPDATE]: [Accessibility.VIEW, Accessibility.EDIT],
        [PagesEnum.BIKER_ACTIVATION]: [Accessibility.VIEW, Accessibility.EDIT],
        [PagesEnum.CUSTOMERS]: [Accessibility.VIEW],
        [PagesEnum.TRIPS]: [Accessibility.VIEW],
        [PagesEnum.WALLET_HISTORY]: [Accessibility.VIEW, Accessibility.EDIT],
        [PagesEnum.INSTALLMENT]: [Accessibility.VIEW],
        [PagesEnum.INCENTIVES]: [Accessibility.VIEW],
        [PagesEnum.TOP_UP_AND_DEDUCTIONS]: [Accessibility.VIEW, Accessibility.EDIT],
        [PagesEnum.GENERAL_SETTINGS]: [Accessibility.VIEW, Accessibility.EDIT],
        [PagesEnum.OPERATORS]: [],
        [PagesEnum.LOGS]: [Accessibility.VIEW],
        [PagesEnum.GCASH_TRANSACTIONS]: [],
        [PagesEnum.CUSTOMER_TRANSACTIONS]: [],
        [PagesEnum.DRIVER_TRANSACTIONS]: [],
        [PagesEnum.ZONES]: [],
    },
};

export const getPermissions = (role: string, page: string): string[] | null => {
    // Check if the role exists in the roles object
    if (permissions.hasOwnProperty(role)) {
        // Check if the page exists in the permissions for the role
        if (permissions[role].hasOwnProperty(page)) {
            return permissions[role][page];
        } else {
            // If the page doesn't exist, return null or handle accordingly
            return null;
        }
    } else {
        // If the role doesn't exist, return null or handle accordingly
        return null;
    }
};

export const roles = [
    { value: 'SUPER_ADMIN', label: 'Super Admin' },
    { value: 'OPERATOR', label: 'Operator' },
    { value: 'FINANCE', label: 'Finance' },
    { value: 'FINANCE_MANAGER', label: 'Finance Manager' },
    { value: 'CITY_MANAGER', label: 'City Manager' },
    { value: 'PRODUCT_OWNER', label: 'Product Owner' },
    { value: 'TRANSPORT_OPS', label: 'Transport Ops' },
    { value: 'IT_OPS', label: 'IT Ops' },
    { value: 'QUALITY_ASSURANCE', label: 'Quality Assurance' },
    { value: 'SERVICE_EXCELLENT', label: 'Service Excellence' },
    { value: 'MARKETING', label: 'Marketing' },
    { value: 'ONBOARDING', label: 'Transport Ops - Onboarding Team' },
    { value: 'DREX', label: 'Transport Ops - DREX Team' },
    { value: 'OPS_STRATEGY_1', label: 'Transport Ops - Strategy 1' },
    { value: 'OPS_STRATEGY_2', label: 'Transport Ops - Strategy 2' },
    { value: 'EXPANSION', label: 'Transport Ops - Expansion' },
    { value: 'SERVICE_EXCELLENT_AGENT', label: 'SE Agent' },
    {
        value: 'SERVICE_EXCELLENT_SUPPORT',
        label: 'SE Support',
    },
    { value: 'VISMIN_ONBOARDING', label: 'VisMin Onboarding' },
    { value: 'ONBOARDING_MANAGER', label: 'Onboarding Manager' },
];

export const convertValueToLabel = (value: any) => {
    const role = roles.find(role => role.value === value);
    return role ? role.label : 'Unknown Role';
};
